<template>
  <div class="content-wrap">
    <div class="up-btn" @click="$refs.secretFileRef.show()">
      {{ $t("LB_UploadingAttachments") }}
      <!-- 上传附件 -->
      <div class="up-tips">
        {{ $t("train.attachment_accept") }}
        <!-- 支持图片、文档、音频、视频，压缩包，最多上传20个附件，单个文件不超过1G -->
      </div>
    </div>
    <div class="activity content">
      <template v-if="trainAttachmentList && trainAttachmentList.length">
        <template v-if="files.image.length">
          <p class="mark">
            <em class="line"></em> {{ $t("train.activity_photos") }}
          </p>
          <!-- 活动图片 -->
          <ul class="v-list">
            <template v-for="item in files.image" :key="item.documentId">
              <li>
                <a-image class="img" :src="item.filePath" />
              </li>
            </template>
          </ul>
        </template>
        <template v-if="files.other.length">
          <p class="mark">
            <em class="line"></em> {{ $t("train.activity_attachments") }}
          </p>
          <!-- 活动附件 -->
          <div class="other-file">
            <ul>
              <li v-for="item in files.other" :key="item.documentId">
                <a-tooltip placement="bottom">
                  <template #title>
                    <template v-if="item.secretData">
                      {{ htsySecret[item.secretData] }}
                    </template>
                    {{ item.fileName + "." + item.suffix }}
                  </template>
                  <a href="javascript:;" @click="viewOrDownFile(item)">
                    <img
                      v-if="item.fileType == 1"
                      src="../../../../assets/image/train/icon_shipin.png"
                    />
                    <img
                      v-if="item.fileType == 2"
                      src="../../../../assets/image/train/icon_yinpin.png"
                    />
                    <img
                      v-if="item.fileType == 3"
                      src="../../../../assets/image/train/icon_wendang.png"
                    />
                    <img
                      v-if="item.fileType == 6"
                      src="../../../../assets/image/train/icon_h5.png"
                    />
                    <img
                      v-if="item.fileType == 11"
                      src="../../../../assets/image/train/icon_wendang.png"
                    />
                    <img
                      v-if="item.fileType == 12"
                      src="../../../../assets/image/train/zip.png"
                    />
                    <span class="title">
                      <template v-if="item.secretData">
                        {{ htsySecret[item.secretData] }}
                      </template>
                      {{ item.fileName }}
                    </span>
                  </a>
                </a-tooltip>
              </li>
            </ul>
          </div>
        </template>
      </template>
      <a-empty
        v-else
        :description="$t('no_attachment')"
        style="padding: 100px 0"
      />
      <!-- 暂无附件 -->
    </div>
  </div>

  <a-modal
    v-model:visible="videoVisible"
    :title="videoTiele"
    width="1000px"
    @ok="handleCancelVideo"
    @cancel="handleCancelVideo"
    destroyOnClose
  >
    <video
      :src="videoSrc"
      autoplay
      controls
      style="width: 100%; height: 60vh"
    ></video>
  </a-modal>
  <secretFile
    ref="secretFileRef"
    :config="{
      action: 'study/upload/file',
      accept: $t('train.attachment_accept'),
      // 支持图片、文档、音频、视频，压缩包，最多上传20个附件，单个文件不超过1G
      maxSize: 1024,
      sizeTip: $t('upload.max_size_tip', ['1G']),
      // 文件大小不能超过1G
      limit: 20,
    }"
    @ok="upEnd"
  />
  <docPreview
    :isVisible="previewVisible"
    :documentId="fileDocumentId"
    :fileStatus="fileStatus"
    :fileType="fileType"
    :filePath="filePath"
    :fileName="fileName"
    :fileHome="fileHome"
    @previewEnd="
      (e) => {
        previewVisible = e;
      }
    "
  />
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { uploadUrl, getHeader } from "@/utils/tools";
import { trainDetail } from "@/api/train";
import { trainMembersUpload } from "@/api/teacher";
import secretFile from "@/components/secretFile.vue";
import { htsySecret } from "@/utils/business";
import docPreview from "@/components/preview/index.vue";
import { getFileSize, viewOrDownFile } from "@/utils/tools";
import { imgRegex } from "@/utils/formRules";
export default {
  components: {
    secretFile,
    docPreview,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    taskId: {
      type: Number,
      default: 0,
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      files: {
        image: [],
        other: [],
      },
      upVisible: false,
      fileList: [],
      videoVisible: false,
      videoTiele: "",
      videoSrc: "",
      confirmLoading: false,
      processVisible: false,
      progress: 0,
      previewVisible: false,
      fileStatus: 1,
      fileDocumentId: 0,
      fileType: "",
      filePath: "",
      fileName: "",
      fileHome: "",
      trainAttachmentList: [],
    });

    const allRender = (list) => {
      if (!list) return false;
      list.map((item) => {
        if (imgRegex.test(item.filePath)) {
          state.files.image.push(item);
        } else {
          state.files.other.push(item);
        }
      });
    };
    allRender(props.data.trainAttachmentList);

    state.trainAttachmentList = props.data.trainAttachmentList;

    const getDetailInfo = () => {
      state.files = {
        image: [],
        other: [],
      };
      trainDetail(props.taskId, props.detailId).then((res) => {
        state.trainAttachmentList = res.data.trainAttachmentList;
        allRender(res.data.trainAttachmentList);
      });
    };

    const secretFileRef = ref(null);
    const upEnd = ({ secret, file }) => {
      state.fileList = file;
      upOk(secret);
    };

    const upOk = (secret) => {
      let files = state.fileList.map((item) => {
        const temp = item.response.data.exist
          ? item.response.data.md5
          : item.response.data.file;
        if (item.status == "done") {
          return {
            name: item.response.data.name,
            file: temp,
            secretData: item.secretData,
            fileType: item.response.data.fileType,
          };
        }
      });
      if (state.trainAttachmentList) {
        state.trainAttachmentList.map((item) => {
          files.push({
            name: item.fileName,
            file: item.md5,
            secretData: item.secretData,
            fileType: item.fileType,
          });
        });
      }
      trainMembersUpload({
        taskId: props.taskId,
        detailId: props.detailId,
        files,
        secretData: secret,
      }).then(() => {
        upCancel();
        getDetailInfo();
      });
    };

    const upCancel = () => {
      state.upVisible = false;
      state.fileList = [];
      state.confirmLoading = false;
    };

    const handleCancelVideo = () => {
      state.videoVisible = false;
    };

    const preview = (data) => {
      state.previewVisible = true;
      state.fileStatus = data.status;
      state.fileDocumentId = data.documentId || 0;
      state.fileType = data.fileType;
      state.filePath = data.filePath;
      state.fileName = data.fileName || "";
      state.fileHome = data.home || "";
    };

    return {
      uploadUrl,
      getHeader,
      getFileSize,
      viewOrDownFile,
      ...toRefs(state),
      upOk,
      upCancel,
      handleCancelVideo,
      upEnd,
      secretFileRef,
      preview,
      htsySecret,
    };
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background-color: #fff;
  padding: 30px 24px 14px;
  .up-btn {
    position: relative;
    width: 176px;
    height: 56px;
    font-size: 22px;
    line-height: 56px;
    text-align: center;
    color: #fff;
    background-color: @color-theme;
    border-radius: 6px;
    cursor: pointer;
    .up-tips {
      position: absolute;
      left: 200px;
      top: 50%;
      transform: translateY(-50%);
      width: 340px;
      color: #999;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      pointer-events: none;
      cursor: none;
    }
  }
  .activity {
    min-height: 756px;
    margin-top: 10px;
    .mark {
      font-size: 15px;
      font-weight: bold;
      margin: 30px 0;
      .line {
        width: 5px;
        height: 16px;
        display: inline-block;
        background: @color-theme;
        vertical-align: -2px;
      }
    }
    .info-file {
      ul {
        li {
          padding: 8px 15px;
          background: #f1f1f1;
          margin-bottom: 15px;
          color: #8b8b8b;
          width: 50%;
          border-radius: 6px;
          display: flex;
          justify-content: left;
          align-items: center;
          cursor: pointer;
          &:hover {
            color: @color-theme;
          }
          .title {
            margin-left: 10px;
            .mixinEllipsis(15px);
          }
        }
      }
    }
    .other-file {
      ul {
        li {
          width: 72px;
          margin-bottom: 15px;
          margin-right: 30px;
          border-radius: 6px;
          display: inline-flex;
          flex-direction: column;
          text-align: center;
          cursor: pointer;
          font-size: 10px;
          img {
            margin-bottom: 12px;
          }
          &:hover {
            color: @color-theme;
          }
          .title {
            .mixinEllipsis(34px,2);
          }
        }
      }
    }
    .v-list {
      display: inline-block;
      margin-bottom: -20px;
      li {
        position: relative;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover {
          .title {
            color: @color-theme;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        // ::v-deep(.img){
        //   width: 262px;
        //   height: 172px;
        //   margin-bottom: 5px;
        // }
        ::v-deep(.ant-image) {
          width: 262px;
          height: 172px;
          margin-bottom: 5px;
          overflow: hidden;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        video {
          width: 262px;
          height: 148px;
        }
        .mask {
          position: absolute;
          width: 262px;
          height: 148px;
          background: #0000005c;
          line-height: 196px;
          text-align: center;
          .icon-play {
            display: inline-block;
            width: 60px;
            height: 60px;
            background-size: contain;
            background-image: url(../../../../assets/image/play.png);
          }
        }
      }
    }
  }
}
.up-box {
  .mixinFlex(space-between; center);
  margin-bottom: 10px;
  .tips {
    width: calc(100% - 130px);
    color: #999;
  }
}
.check-process {
  padding: 10px 20px 0;
  text-align: center;
}
</style>
